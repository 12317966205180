import { Grid } from '@velocity/ui'
import * as R from 'ramda'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import {
  ContactType,
  type ContactFormValues,
  type ContactStepPrerequisiteData,
  type ContactStepValues,
  type StepProps,
} from '@ngb-frontend/shared/types'
import { ContactView, FullScreenLoader } from '@ngb-frontend/shared/ui'
import {
  getContactStepDataFromBooking,
  useDriver,
  useQuery,
  parseDriverContact,
  useBookingRequest,
  useVehicle,
} from '@ngb-frontend/shared/utils'

import { ContactNotificationBanner } from '../../ContactNotificationBanner/ContactNotificationBanner'

const defaultFormValues: ContactFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
}

const ContactStep: React.FC<
  StepProps<ContactStepValues, ContactStepPrerequisiteData>
> = ({ onNextStep, onUpdateStep, stepData }) => {
  const query = useQuery()
  const { data: vehicle } = useVehicle()

  const { data: bookingData, isLoading: bookingLoading } = useBookingRequest(
    query?.bookingId,
  )
  const bookingContact = useRef<ContactStepValues>()
  const booking = bookingData?.[0]
  // TODO: investigate why in some cases stepData can be an array
  const hasStepData = stepData && !Array.isArray(stepData)

  const { data: sfDriver, isLoading: driverLoading } = useDriver(
    vehicle?.driverIdentifier || query?.licensePlate,
    query?.country,
  )

  // Apply the correct initial data to step
  useEffect(() => {
    if (hasStepData || bookingLoading || driverLoading) return

    if (booking) {
      bookingContact.current = getContactStepDataFromBooking(booking)

      onUpdateStep(
        bookingContact.current.contactType === ContactType.Other
          ? bookingContact.current
          : { contactType: ContactType.Driver, contact: defaultFormValues },
      )
      return
    }

    onUpdateStep({
      contactType: ContactType.Driver,
      contact: defaultFormValues,
    })
  }, [booking, bookingLoading, driverLoading, hasStepData, onUpdateStep])

  const disableDriver = sfDriver && (!sfDriver.email || !sfDriver?.mobile_phone)
  const driverFormValues: ContactFormValues | undefined = useMemo(
    () => sfDriver && parseDriverContact(sfDriver),
    [sfDriver],
  )

  const handleSubmit = React.useCallback(
    (values: ContactFormValues, contactType: ContactType) => {
      onUpdateStep({
        contactType,
        contact: values,
      })
      onNextStep()
    },
    [onUpdateStep, onNextStep],
  )

  const onContactChange = useCallback(
    (contactType: ContactType) => {
      onUpdateStep({
        contact:
          bookingContact.current?.contactType === ContactType.Driver
            ? defaultFormValues
            : bookingContact.current?.contact || defaultFormValues,
        contactType,
      })
    },
    [onUpdateStep],
  )

  if (!hasStepData) {
    return <FullScreenLoader />
  }

  return (
    <>
      <Grid margin={{ bottom: '08' }}>
        <Grid.Item LG={6}>
          <ContactNotificationBanner />
        </Grid.Item>
      </Grid>
      <ContactView
        compact
        onContactChange={onContactChange}
        driverFormValues={driverFormValues}
        formValues={stepData.contact}
        contactType={stepData.contactType}
        onSubmit={handleSubmit}
        disableDriver={disableDriver}
      />
    </>
  )
}

const hasContactDetails = (x: unknown): x is ContactFormValues => {
  return R.allPass([
    R.pipe(R.prop('firstName'), R.is(String)),
    R.pipe(R.prop('lastName'), R.is(String)),
    R.pipe(R.prop('email'), R.is(String)),
    R.pipe(R.prop('phone'), R.is(String)),
  ])(x)
}

export const hasContactFormValues = (x: unknown): x is ContactStepValues =>
  R.allPass([
    R.pipe(R.prop('contact'), hasContactDetails),
    R.pipe(R.prop('contactType'), R.is(String)),
  ])(x)

export default ContactStep
