import getConfig from 'next/config'

import {
  getTestServerConfig,
  getTestClientConfig,
  getTestSharedConfig,
} from '@ngb-frontend/shared/test-utils'

import { isJsDom } from '../isJsDom/isJsDom'
import { string } from '../string/string'
import { truthy } from '../string/truthy'

import type {
  ClientConfig,
  ServerConfig,
  SharedStaticConfig,
  SharedConfig,
} from '@ngb-frontend/shared/types'

/**
 * In app code these configs should be consumed through getConfig that's why
 * this is not exported. To avoid * errors/redundant code, a copy of these
 * configurations is maintained in libs/shared/mocks for usage in other contexts (cy)
 */
const sharedStaticConfig: SharedStaticConfig = {
  apiRoutes: {
    driver: '/api/driver',
    booking: '/api/booking',
    createBooking: '/api/booking?action=create',
    updateBooking: '/api/booking?action=update',
    fetchBooking: '/api/booking?action=fetch',
    fetchBookingHistory: '/api/booking?action=fetchHistory',
    leaseChanges: '/api/contract/leaseChanges',
    supplierSearch: '/api/supplierSearch',
    vehicle: '/api/vehicle',
    serviceHistory: '/api/serviceHistory',
  },
  endpoints: {
    azurePublicKeys: 'https://login.microsoftonline.com',
    booking: 'booking',
    driver: 'driver',
    contract: 'contracts',
    supplierSearch: 'supplier-search',
    vehicleObject: 'vehicle-objects',
    serviceHistory: 'service_history/views/service_history',
    vehicleList: 'vehicle/views/vehicle-list',
    vehicleDetails: 'vehicle/views/vehicle-details',
  },
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getSharedConfig = (pubConfig?: any): SharedConfig => {
  if (isJsDom()) return getTestSharedConfig()

  return {
    ...sharedStaticConfig,
    disableAuth: truthy(pubConfig?.disableAuth),
    mockApi: truthy(pubConfig?.mockApi),
    buildInfo: {
      productName: 'Next Generation Booking',
      commitSha: pubConfig?.commit,
      branch: pubConfig?.branch,
      timestamp: '',
    },
    features: {
      eligibilityCheck: truthy(pubConfig?.features.eligibilityCheck),
      driverCourtesy: !truthy(pubConfig?.features.disableCourtesy),
      disableNFFleet: truthy(pubConfig?.features.disableNFFleet),
    },
    azure: {
      azureAD: {
        clientId: string(pubConfig?.azureAD.clientId),
        authority: string(pubConfig?.azureAD.authority),
      },
      azureEntraID: {
        clientId: string(pubConfig?.azureEntraID.clientId),
        authority: string(pubConfig?.azureEntraID.authority),
      },
      azureB2C: {
        clientId: string(pubConfig?.azureB2C.clientId),
        authority: string(pubConfig?.azureB2C.authority),
        scope: string(pubConfig?.azureB2C.scope),
      },
    },
  }
}

export const getClientConfig = (): ClientConfig => {
  if (isJsDom()) return getTestClientConfig()

  const { publicRuntimeConfig: pubConfig } = getConfig()

  const { applicationId, ...ddSharedConfigs } = pubConfig?.dd || {}

  return {
    ...getSharedConfig(pubConfig),
    google: {
      maps: pubConfig?.maps,
    },
    onetrust: pubConfig?.onetrust,
    disableAyvensOnLP: pubConfig?.disableAyvensOnLP,
    datadogLogConfig: {
      forwardErrorsToLogs: true,
      ...ddSharedConfigs,
    },
    datadogRumConfig: {
      applicationId,
      ...ddSharedConfigs,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'mask',
      allowedTracingUrls: [
        /http:\/\/localhost.+\/api\/.+/,
        /https:\/\/ngb-frontend.+\/api\/.+/,
        /https:\/\/eu-.+-api\.leaseplan\.com.+/,
        /https:\/\/ngbooking.leaseplan.com\/api\/.+/,
        /https:\/\/servicebooking.ayvens.com\/api\/.+/,
      ],
    },
    hotjarConfig: {
      id: pubConfig?.hotjarId,
      version: 6,
    },
  }
}

export const getServerConfig = (): ServerConfig => {
  if (isJsDom()) return getTestServerConfig()

  const { publicRuntimeConfig: pubConfig } = getConfig()

  return {
    ...getSharedConfig(pubConfig),
    disableToken: truthy(process.env.DISABLE_TOKEN),
    sfBase: string(process.env.SF_BASE_URL),
    sfBaseALD: string(process.env.SF_BASE_URL_ALD),
    fleetopsContractManagementBase: string(
      process.env.FLEETOPS_CONTRACT_MANAGEMENT_BASE_URL,
    ),
    fleetopsLeaseObjectBase: string(process.env.FLEETOPS_LEASE_OBJECT_BASE_URL),
    apiTokenUrl: string(process.env.API_TOKEN_URL),
    apiTokenUrlALD: string(process.env.API_TOKEN_URL_ALD),
    tibco: {
      sfClientId: string(process.env.SF_CLIENT_ID),
      sfSecret: string(process.env.SF_SECRET),
      fleetopsContractManagementClientId: string(
        process.env.FLEETOPS_CONTRACT_MANAGEMENT_CLIENT_ID,
      ),
      fleetopsContractManagementSecret: string(
        process.env.FLEETOPS_CONTRACT_MANAGEMENT_SECRET,
      ),
      fleetopsLeaseObjectClientId: string(
        process.env.FLEETOPS_LEASE_OBJECT_CLIENT_ID,
      ),
      fleetopsLeaseObjectSecret: string(
        process.env.FLEETOPS_LEASE_OBJECT_SECRET,
      ),
    },
    aldAuth: {
      sfClientId: string(process.env.ALD_SF_CLIENT_ID),
      sfSecret: string(process.env.ALD_SF_SECRET),
      sfUser: string(process.env.ALD_SF_USERNAME),
      sfPassword: string(process.env.ALD_SF_PASSWORD),
    },
    denodo: {
      base: string(process.env.DENODO_BASE),
      username: string(process.env.DENODO_USERNAME),
      password: string(process.env.DENODO_PASSWORD),
    },
    ipInfoToken: string(process.env.IPINFO_TOKEN),
    smartling: {
      projectId: string(process.env.SMARTLING_PROJECT_ID),
      userId: string(process.env.SMARTLING_ID),
      secret: string(process.env.SMARTLING_SECRET),
      gitToken: string(process.env.SMARTLING_BOT_TOKEN),
    },
  }
}
