import type { QueryCountryCode } from '@ngb-frontend/shared/types'

export const getCountryFromFeatureFlag = (
  code?: QueryCountryCode,
  { disableNFFleet }: { disableNFFleet?: boolean } = {},
) => {
  if (code === '75202' && disableNFFleet) {
    return '75201'
  }
  return code
}
