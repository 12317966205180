/* eslint-disable @next/next/no-sync-scripts */
import '../../public/global.css'
import logBuildInfo from '@cj4/log-build-info'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import Hotjar from '@hotjar/browser'
import { logger } from '@velocity/ui'
import Head from 'next/head'
import { useEffect, Fragment } from 'react'

import {
  AppConfigServiceProvider,
  LocalStorageProvider,
} from '@ngb-frontend/shared/context'
import { initApiMocks, setupGoogleMock } from '@ngb-frontend/shared/test-utils'
import { CustomVUILogger, getClientConfig } from '@ngb-frontend/shared/utils'

import { PageSession } from '../components/PageSession'

import type { PageProps as ConfirmPageProps } from '../pages/confirm/[bookingId].page'
import type { PageProps as IndexPageProps } from '../pages/index.page'
import type { AppProps } from 'next/app'

type ChildPageProps = Partial<IndexPageProps | ConfirmPageProps>

const clientConfig = getClientConfig()

function App({ Component, pageProps }: AppProps<ChildPageProps>) {
  // Start with the client ctx and logging/testing
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')
    jssStyles?.parentElement?.removeChild(jssStyles)

    clientConfig.datadogLogConfig &&
      datadogLogs.init(clientConfig.datadogLogConfig)
    // Rum session replay starts automatically
    clientConfig.datadogRumConfig &&
      datadogRum.init(clientConfig.datadogRumConfig)

    if (clientConfig.hotjarConfig.id) {
      const { id, version } = clientConfig.hotjarConfig
      Hotjar.init(parseInt(id, 10), version)
    }

    logBuildInfo(clientConfig.buildInfo)

    // @ts-ignore
    if (window['Cypress']) window.google = setupGoogleMock()

    clientConfig.mockApi && initApiMocks(clientConfig)
    // eslint-disable-next-line react-hooks/exhaustive-deps

    logger.setCustomLogger({
      logger: CustomVUILogger,
      preventConsoleLogging: true,
    })
  }, [])

  return (
    <Fragment>
      <Head key="app">
        <title>NGB</title>
        <meta name="description" content="Book services for your vehicle" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      {clientConfig.onetrust && (
        <>
          <script
            type="text/javascript"
            src={`https://cdn.cookielaw.org/consent/${clientConfig.onetrust}/OtAutoBlock.js`}
          />
          <script
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            type="text/javascript"
            data-domain-script={`${clientConfig.onetrust}`}
          />
          <script type="text/javascript" id="onetrust">
            {`function OptanonWrapper() {}`}
          </script>
        </>
      )}
      <AppConfigServiceProvider value={clientConfig}>
        {/* This should be moved in PageSession as part of remodelling our app
        context setup and flow validation/initialization */}
        <LocalStorageProvider>
          <PageSession component={Component} {...pageProps} />
        </LocalStorageProvider>
      </AppConfigServiceProvider>
    </Fragment>
  )
}

export default App
