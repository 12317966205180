import { Button } from '@velocity/ui'
import { useMemo } from 'react'

import { useContent, useLocalization } from '@ngb-frontend/shared/context'
import { formatPhoneNumber } from '@ngb-frontend/shared/utils'

const getPhoneNumber = (country = '', defaultPhoneNumber: string) => {
  switch (country) {
    case '64201':
      return '+40-021-301-49-59'
    case '75601':
      return '+41-746-63-33'
    case '75201':
      return '+46-8-501-122-00'
    case '75202':
      return '+46-8-501-123-60'
    default:
      return defaultPhoneNumber
  }
}

export const PhoneButton: React.FC = () => {
  const c = useContent()
  const { country } = useLocalization()

  const phone = useMemo(() => {
    const phoneNumber = getPhoneNumber(country, c('steps.shared.phoneNumber'))
    return formatPhoneNumber(phoneNumber)
  }, [c, country])

  return (
    <Button
      size="s"
      component="a"
      href={`tel:${phone}`}
      variant="primary-filled"
    >
      {phone}
    </Button>
  )
}
