import useSWR from 'swr'

import { useAppConfig } from '@ngb-frontend/shared/context'
import {
  type Booking,
  type Driver,
  type MalfunctionCode,
  type ServiceValue,
  type SummaryStepPrerequisiteData,
  type Supplier,
  type Address,
  type InspectionCode,
  type RequestType,
  type SummaryStepPrerequisiteDataDCC,
  type ReplacementVehicleDate,
  TimeSlot,
  RepossessionType,
  type Vehicle,
  type CreateBookingResponse,
} from '@ngb-frontend/shared/types'

import { useQuery } from './useQuery'
import {
  getSFDriverContact,
  getSFOtherContact,
} from '../object/converters/getContact'
import { mapServiceToRPVUniversalServiceCode } from '../replacementVehicle/mapServiceToRPVUniversalServiceCode'
import { isLPSystem } from '../system/isLPSystem'

import type { RequestError } from '../error/RequestError'

export const useBookingRequest = (bookingId?: string) => {
  const config = useAppConfig()

  return useSWR<Booking[]>(
    bookingId ? [config.apiRoutes.fetchBooking, { bookingId }] : null,
  )
}

export const useUpdateBooking = (
  booking: Partial<Booking>,
  shouldSubmit: boolean,
) => {
  const config = useAppConfig()

  const res = useSWR(
    booking.id && shouldSubmit
      ? [config.apiRoutes.updateBooking, booking]
      : null,
  )

  // Update booking returns empty string
  return {
    ...res,
    data: typeof res.data !== 'undefined',
  }
}

type CreateBookingUserData = Pick<
  SummaryStepPrerequisiteData,
  'mileage' | 'dateTime' | 'location' | 'additionalInformation'
> &
  Pick<
    Partial<SummaryStepPrerequisiteData>,
    | 'services'
    | 'extraServices'
    | 'contact'
    | 'courtesyVehicle'
    | 'replacementVehicle'
  > &
  Pick<
    Partial<SummaryStepPrerequisiteDataDCC>,
    | 'driverDeceased'
    | 'vehicleDrivable'
    | 'vehicleNotDrivableReason'
    | 'timeSlot'
    | 'repossession'
  > & {
    replacementVehicleCategory?: string
    replacementVehicleDate?: ReplacementVehicleDate
  }

type CreateBookingFetchedData = {
  vehicle?: Vehicle
  driver?: Omit<Partial<Driver>, 'id' | 'country_code'> &
    Pick<Driver, 'id' | 'country_code'>
  supplier?: Supplier
  replacementVehicleCategory?: Vehicle['replacementVehicleCategory']
  replacementVehicleAddress?: Address
}

export type CreateBookingConfig = CreateBookingUserData &
  CreateBookingFetchedData & {
    id?: string | null
    shouldSubmit: boolean
    malfunctions?: MalfunctionCode[]
    malfunctionDescription?: string
    inspection?: InspectionCode
    requestType: RequestType
  }

export const useCreateBooking = ({
  id = null,
  shouldSubmit,
  vehicle,
  inspection,
  driver,
  supplier,
  dateTime,
  location,
  mileage,
  services,
  extraServices,
  additionalInformation,
  contact,
  courtesyVehicle,
  replacementVehicle,
  replacementVehicleCategory,
  replacementVehicleDate,
  replacementVehicleAddress,
  malfunctions,
  malfunctionDescription,
  requestType,
  driverDeceased,
  vehicleDrivable,
  vehicleNotDrivableReason,
  timeSlot,
  repossession,
}: CreateBookingConfig) => {
  const query = useQuery()
  const appConf = useAppConfig()
  const expectedServiceEndDate = new Date(dateTime)
  expectedServiceEndDate.setUTCHours(17, 0, 0, 0)
  const expectedServiceStartDate = new Date(dateTime)

  if (replacementVehicleDate === 'dayBeforeDate') {
    expectedServiceStartDate.setUTCDate(
      expectedServiceStartDate.getUTCDate() - 1,
    )
    expectedServiceStartDate.setUTCHours(17, 0, 0, 0)
  }

  const replacement_vehicle_data = replacementVehicle
    ? {
        status: 'Unconfirmed',
        requested_vehicle_class: replacementVehicleCategory,
        universal_service_group_code: 'SGP-REPLV',
        universal_service_code: mapServiceToRPVUniversalServiceCode(
          services?.[0] as ServiceValue,
        ),
        priority: 'high',
        expected_rental_period: 1,
        expected_rental_period_unit: 'days',
        expected_service_end_date: expectedServiceEndDate.toISOString(),
        expected_service_start_date: expectedServiceStartDate.toISOString(),
        pickup_address: replacementVehicleAddress,
        return_address: replacementVehicleAddress,
      }
    : null

  const isEOL = requestType === 'EOL'

  const vehicle_return_data = isEOL
    ? {
        drivable: vehicleDrivable,
        customer_deceased: driverDeceased,
        // TODO: Update after https://leaseplan-digital.atlassian.net/browse/NGRE2E-1691
        collection_time: timeSlot === TimeSlot.AllDay ? TimeSlot.N : timeSlot,
        nondrivable_reason: vehicleNotDrivableReason,
        county: location?.county,
        repossession: repossession !== RepossessionType.No,
        repossession_type: repossession,
      }
    : null

  const replacementMobility = replacementVehicle
    ? 'RentalByExternalCompany'
    : courtesyVehicle
    ? 'Courtesy'
    : 'None'

  // For now, enable booking submissions for ALD even without vehicle endpoint
  const hasDeps =
    (!isLPSystem(query?.systemCode) || vehicle) && driver && supplier

  const swr = useSWR<CreateBookingResponse, RequestError>(
    hasDeps && shouldSubmit
      ? [
          appConf.apiRoutes.createBooking,
          {
            id,
            contact_details: getSFOtherContact(contact || {}),
            request_type: requestType,
            user_type: query?.userType,
            mileage,
            country_code: driver.country_code,
            service_date_time: dateTime,
            service: {
              description: additionalInformation,
              items: services?.map((srv) => ({
                code: srv,
                value: srv,
              })),
            },
            additional_services: extraServices?.map((service) => ({
              code: service,
              value: service,
            })),
            agent_id: query?.agentId,
            case_id: query?.caseId,
            driver: {
              ...driver,
              ...(contact ? getSFDriverContact(driver) : {}),
              pickup_address: location,
            },
            supplier,
            vehicle: {
              registration_number: vehicle?.licensePlate || query?.licensePlate,
              make: vehicle?.make,
              type: vehicle?.objectType,
              id: query?.vehicleIdentifier.value,
            },
            malfunctions: [
              ...(malfunctions || []),
              ...(!malfunctionDescription?.length ? [] : ['Other']),
            ],
            malfunction_description: malfunctionDescription,
            mot_type: inspection,
            courtesy_vehicle: courtesyVehicle,
            replacement_mobility: replacementMobility,
            replacement_vehicle_data,
            vehicle_return_data,
            service_slot: timeSlot,
          },
        ]
      : null,
  )

  return swr
}
