import { Referer, SystemCode, AppTheme } from '@ngb-frontend/shared/types'

export const systemCodeToReferrer = (
  systemCode?: SystemCode,
  theme?: AppTheme,
): Referer => {
  switch (systemCode) {
    case SystemCode.SFALD:
      return Referer.SalesForce
    case SystemCode.SFLP:
      return Referer.SalesForce
    case SystemCode.SPLP:
      return Referer.SalesPortal
    case SystemCode.MYAY:
      return theme === AppTheme.Nordea ? Referer.MyNordea : Referer.MyAY
    case SystemCode.MYLP:
    default:
      return Referer.MyLP
  }
}
