import { localStorageKeys } from './config'

export const swrCacheProvider = () => {
  if (typeof window === 'undefined') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new Map<string, any>()
  }

  // When initializing, we restore the data from `localStorage` into a map.
  // Needed <string, any> in order for swr to accept the map as provider
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let map: Map<string, any>
  try {
    map = new Map(
      JSON.parse(localStorage.getItem(localStorageKeys.swrCache) || '[]'),
    )
  } catch {
    map = new Map([])
  }

  // Before unloading the app, we write back all the data into `localStorage`.
  window.addEventListener('beforeunload', () => {
    const appCache = JSON.stringify(Array.from(map.entries()))
    localStorage.setItem(localStorageKeys.swrCache, appCache)
  })

  // We still use the map for write & read for performance.
  return map
}
