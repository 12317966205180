import { Footer as AppFooter } from '@velocity/ui'
import React, { useMemo } from 'react'

import { useContent } from '@ngb-frontend/shared/context'
import { AppTheme } from '@ngb-frontend/shared/types'
import { useQuery } from '@ngb-frontend/shared/utils'

import { FOOTER_LINKS } from './footerLinks'

export const Footer: React.FC = () => {
  const c = useContent()
  const { legal, target } = FOOTER_LINKS
  const query = useQuery()
  const date = useMemo(() => new Date().getFullYear(), [])

  const OneTrustBtn = useMemo(() => {
    // @ts-ignore
    const displayOTInfo = window.OneTrust?.ToggleInfoDisplay

    if (!displayOTInfo) return

    return (
      <AppFooter.Legal.Link onClick={() => displayOTInfo()}>
        {c('footer.manageCookies')}
      </AppFooter.Legal.Link>
    )
  }, [c])

  const copyRightComponent = useMemo(
    () => (
      <span data-chromatic="ignore">{`© ${date} ${
        query?.theme || AppTheme.Ayvens
      }`}</span>
    ),
    [date, query?.theme],
  )

  const links = useMemo(() => {
    return legal.reduce((acc, { link, label }) => {
      const linkString = c(`footer.${link}`, { fallback: '' })

      if (!linkString) return acc

      return [
        ...acc,
        <AppFooter.Legal.Link href={linkString} target={target} key={link}>
          {c(`footer.${label}`)}
        </AppFooter.Legal.Link>,
      ]
    }, [] as JSX.Element[])
  }, [c, legal, target])

  return (
    <AppFooter
      layoutContainerSize="fixed-wide"
      variant="brand"
      copyright={copyRightComponent}
    >
      <AppFooter.Legal>
        {OneTrustBtn}
        {links}
      </AppFooter.Legal>
    </AppFooter>
  )
}
