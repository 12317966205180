import { useCallback, useContext } from 'react'
import { useSWRConfig } from 'swr'

import { StorageContext } from './LocalStorageProvider'

import type { LocalStorageKey, LocalStorageValue } from './config'
import type { Feature } from '@ngb-frontend/shared/types'

export const useStorage = <TFeature extends Feature>() => {
  const ctx = useContext(StorageContext) as StorageContext<TFeature>
  if (!ctx) {
    throw new Error(
      'No Context value found for `stores`. Did you forget to wrap your application in the `StorageProvider`?',
    )
  }
  const { cache } = useSWRConfig()

  const { stores: localStorageStores } = ctx

  const getLocalStorage = <T extends LocalStorageKey>(
    key: T,
  ): LocalStorageValue<T, TFeature> | null => {
    return localStorageStores[key].get() as LocalStorageValue<
      T,
      TFeature
    > | null
  }

  const setLocalStorage = <TKey extends LocalStorageKey>(
    key: TKey,
    value: LocalStorageValue<TKey, TFeature>,
  ): void => {
    localStorageStores[key].set(value)
  }

  const updateLocalStorage = <TKey extends LocalStorageKey>(
    key: TKey,
    updater: (
      prev: LocalStorageValue<TKey, TFeature> | null,
    ) => LocalStorageValue<TKey, TFeature>,
  ): void => {
    const currentValue = getLocalStorage(key)
    setLocalStorage(
      key,
      updater(currentValue) as LocalStorageValue<TKey, TFeature>,
    )
  }

  const clearLocalStorage = useCallback(
    <T extends LocalStorageKey>(clearConfig?: {
      include?: T[]
      exclude?: T[]
    }) => {
      const allKeys = Object.keys(localStorageStores) as T[]
      const includeKeys = clearConfig?.include || allKeys
      const excludeKeys = clearConfig?.exclude || []

      includeKeys.forEach((key) => {
        if (!excludeKeys.includes(key)) {
          if (key === 'swrCache') {
            Array.from(cache.keys()).forEach((k) => cache.delete(k))
          }
          localStorageStores[key].set(
            null as unknown as LocalStorageValue<T, TFeature>,
          )
        }
      })
    },
    [cache, localStorageStores],
  )

  return {
    getLocalStorage,
    setLocalStorage,
    clearLocalStorage,
    updateLocalStorage,
  }
}
