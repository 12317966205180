import { setDefaultOptions } from 'date-fns/setDefaultOptions'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { DEFAULT_COUNTRY } from './countryLocaleConfig'
import { getCountryL10nConfig } from './utils/countryLocale'
import { getDateFnsLocale } from './utils/locale'

import type { AppLocale, QueryCountryCode } from '@ngb-frontend/shared/types'
import type { Locale as DateFnsLocale } from 'date-fns/locale'
import type { ReactNode } from 'react'

export type LocalizationContext = {
  country: QueryCountryCode
  locale: AppLocale
  availableLocales: AppLocale[]
  formatLocale: DateFnsLocale
  updateLocale: (locale: AppLocale) => void
}

const LocalizationContext = createContext<LocalizationContext | undefined>(
  undefined,
)

interface LocalizationProviderProps {
  country?: QueryCountryCode
  children?: ReactNode
}

// Localization Provider component
export const LocalizationProvider: React.FC<LocalizationProviderProps> = ({
  country = DEFAULT_COUNTRY,
  children,
}) => {
  const countryConfig = getCountryL10nConfig(country)
  const [locale, setLocale] = useState<AppLocale>(countryConfig.defaultLocale)

  // Global date-fns locale configuration
  useEffect(() => {
    setDefaultOptions({ locale: getDateFnsLocale(locale) })
  }, [locale])

  // Update locale function
  const updateLocale = useCallback((locale: AppLocale) => {
    setLocale(locale)
  }, [])

  const ctxConfig = useMemo(() => {
    return {
      locale,
      availableLocales: countryConfig.locales,
      formatLocale: getDateFnsLocale(locale),
      updateLocale,
      country,
    }
  }, [country, countryConfig.locales, locale, updateLocale])

  return (
    <LocalizationContext.Provider value={ctxConfig}>
      {children}
    </LocalizationContext.Provider>
  )
}

export const useLocalization = (): LocalizationContext => {
  const localizationCtx = useContext(LocalizationContext)

  if (!localizationCtx) {
    throw new Error(
      'No Context value found for `Localization`. Did you forget to wrap your application in the `LocalizationProvider`?',
    )
  }

  return localizationCtx
}
