/* eslint-disable import/no-named-as-default */
import AyvensFavicon from '@velocity/icons/favicon/ayvens-favicon-react'
import NordeaFavicon from '@velocity/icons/favicon/nordea-favicon-react'
import Head from 'next/head'
import { useEffect, useMemo } from 'react'

import { useLocalization } from '@ngb-frontend/shared/context'
import { AppTheme } from '@ngb-frontend/shared/types'
import { useQuery } from '@ngb-frontend/shared/utils'

import AyvensThemeProvider from './ThemeProvider/AyvensThemeProvider'
import NordeaThemeProvider from './ThemeProvider/NordeaThemeProvider'

import type { DateFnsLocales } from '@velocity/ui'

const getBrandTheme = (theme?: AppTheme) => {
  switch (theme) {
    case AppTheme.Ayvens:
      return AyvensThemeProvider
    case AppTheme.Nordea:
      return NordeaThemeProvider
    default:
      return AyvensThemeProvider
  }
}

const getBrandCSS = (theme?: AppTheme) => {
  if (theme === AppTheme.Nordea) {
    // @ts-ignore
    import('@velocity/styling/themes/nordea/theme.css')
    return
  }

  // @ts-ignore
  import('@velocity/styling/themes/ayvens/theme.css')
}

const getBrandFavicon = (theme?: AppTheme) => {
  return theme === AppTheme.Nordea ? NordeaFavicon : AyvensFavicon
}

export const NGBVelocityProvider: React.FC<{
  theme?: AppTheme
  children: React.ReactNode
}> = (props) => {
  const { locale, formatLocale } = useLocalization()
  const theme = useQuery()?.theme || AppTheme.Ayvens

  // Favicon does not get picked up as a FC, hence the call
  // https://nextjs.org/docs/pages/api-reference/components/head#use-minimal-nesting
  const [MemoizedThemeProvider, MemoizedFavicon] = useMemo(
    () => [getBrandTheme(theme), getBrandFavicon(theme)()],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    getBrandCSS(theme)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formatingLocales = useMemo((): DateFnsLocales => {
    return { [locale]: formatLocale }
  }, [formatLocale, locale])

  return (
    <>
      <Head>{MemoizedFavicon}</Head>
      <MemoizedThemeProvider
        {...props}
        locale={locale}
        dateFnsLocales={formatingLocales}
      />
    </>
  )
}
