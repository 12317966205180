import { useMemo } from 'react'

import { useAppConfig, useStorage } from '@ngb-frontend/shared/context'
import { AppTheme, TVehicleIdentifier } from '@ngb-frontend/shared/types'

import { getUserType } from '../config/getUserType'
import { getCountryFromFeatureFlag } from '../locale/getCountry'

/**
 * @returns The parsed query params from LS augmented with query-specific,
 * repeatable fields/helpers which the app uses.
 */
export const useQuery = () => {
  const { getLocalStorage } = useStorage()
  const q = getLocalStorage('queryParams')
  const { disableNFFleet } = useAppConfig().features

  const qRes = useMemo(() => {
    if (!q) return q

    const { ilan, licensePlate } = q
    const country = getCountryFromFeatureFlag(q.country, { disableNFFleet })

    return {
      ...q,
      bare: q, // without helpers
      vehicleIdentifier: {
        type: ilan ? TVehicleIdentifier.ILAN : TVehicleIdentifier.REGISTRATION,
        // We don't store a query if none of the 2 is present
        value: ilan || licensePlate || '',
      },
      userType: getUserType(q.systemCode),
      theme:
        country === '75202' && !disableNFFleet
          ? AppTheme.Nordea
          : AppTheme.Ayvens,
    }
  }, [disableNFFleet, q])

  return qRes
}
