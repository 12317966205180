import { LocalstorageStore } from '@cj4/store-localstorage'
import { createContext, useMemo, useRef } from 'react'

import { localStorageKeys } from './config'

import type { LocalStorageKey, LocalStorageValue } from './config'
import type { Feature } from '@ngb-frontend/shared/types'

export type StorageContext<TFeature extends Feature = Feature.MnR> = Readonly<{
  keys: typeof localStorageKeys
  stores: {
    [TKey in LocalStorageKey]: LocalstorageStore<
      LocalStorageValue<TKey, TFeature>
    >
  }
}>

const createLocalStorageStores = <TFeature extends Feature>(): Record<
  LocalStorageKey,
  LocalstorageStore<LocalStorageValue<LocalStorageKey, TFeature>>
> => {
  return Object.entries(localStorageKeys).reduce((acc, [key, value]) => {
    acc[key as LocalStorageKey] = new LocalstorageStore(value)
    return acc
  }, {} as Record<LocalStorageKey, LocalstorageStore<LocalStorageValue<LocalStorageKey, TFeature>>>)
}

export const StorageContext = createContext<
  StorageContext<Feature> | undefined
>(undefined)

export const LocalStorageProvider = ({ children }: React.PropsWithChildren) => {
  const localStorageStoresRef = useRef(createLocalStorageStores())
  const ctx = useMemo(
    () => ({
      keys: localStorageKeys,
      stores: localStorageStoresRef.current,
    }),
    [],
  )
  return (
    <StorageContext.Provider value={ctx as StorageContext}>
      {children}
    </StorageContext.Provider>
  )
}
