import { CarDamagePictogram } from '@velocity/icons/pictogram'
import { useMemo } from 'react'

import { FlexBox } from '../FlexBox/FlexBox'
import { NotificationView } from '../NotificationView/NotificationView'
import { StepLayout as Layout } from '../StepLayout/StepLayout'

import type { FallbackProps } from 'react-error-boundary'

// NB: In dev environment, the NextJS error overlay will still be shown
// https://github.com/vercel/next.js/discussions/13387

export const boundaryErrors = {
  unknownPath: 'Unknown step path',
  generic: 'Unfortunately, something went wrong',
}

/**
 * This component wraps also all providers, hence the message it displays cannot
 * be localized and should instead be in English.
 */
export const ErrorBoundaryFallback: React.FC<FallbackProps> = ({ error }) => {
  const ErrorComponent = useMemo(() => {
    if (error.message === boundaryErrors.unknownPath) {
      return (
        <NotificationView
          Icon={CarDamagePictogram}
          headline={boundaryErrors.unknownPath}
        />
      )
    }

    return (
      <NotificationView
        Icon={CarDamagePictogram}
        headline={boundaryErrors.generic}
      />
    )
  }, [error.message])

  return (
    <Layout navLabel={''} showLogo={true}>
      <FlexBox>{ErrorComponent}</FlexBox>
    </Layout>
  )
}
