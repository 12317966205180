import type { QueryParams, FlowData, Feature } from '@ngb-frontend/shared/types'

export const localStorageKeys = {
  swrCache: 'appCache',
  flow: 'ngb-flow',
  queryParams: 'queryParams',
  bookingHistoryChecked: 'bookingHistoryChecked',
  customPlatesChecked: 'customPlatesChecked',
} as const

export type LocalStorageKey = keyof typeof localStorageKeys

export type LocalStorageKeyToValue<TFeature extends Feature = Feature.MnR> = {
  queryParams: QueryParams | null
  flow: FlowData<TFeature>
  bookingHistoryChecked: boolean
  customPlatesChecked: boolean
  swrCache: string
}

export type LocalStorageValue<
  TKey extends LocalStorageKey,
  TFeature extends Feature = Feature.MnR,
> = TKey extends 'flow'
  ? FlowData<TFeature>
  : LocalStorageKeyToValue<TFeature>[TKey]
