import { ChevronRightIcon } from '@velocity/icons/system'
import { Button, Card, Grid, Headline, Text } from '@velocity/ui'
import clsx from 'clsx'
import React from 'react'

import { useContent } from '@ngb-frontend/shared/context'

import { useNotificationViewStyles } from './NotificationView.styled'
import { LinkInline } from '../LinkInline/LinkInline'

import type { Pictogram } from '@velocity/icons/pictogram'
import type { ButtonProps } from '@velocity/ui'
import type { ReactNode } from 'react'

export interface NotificationViewProps {
  onCancel?: () => void
  onSubmit?: () => void
  headline?: string
  submitButtonText?: string
  cancelButtonText?: string
  Icon?: Pictogram
  submitButtonVariant?: ButtonProps['variant']
  children?: ReactNode
}

export const NotificationView: React.FC<NotificationViewProps> = ({
  onCancel,
  onSubmit,
  headline,
  submitButtonText,
  cancelButtonText,
  children,
  Icon,
  submitButtonVariant = 'primary-filled',
}) => {
  const classes = useNotificationViewStyles()
  const c = useContent()

  return (
    <Card className={classes.root}>
      <div className={classes.cardInner}>
        {Icon && (
          <Icon
            size="large"
            className={classes.pictogram}
            data-e2e-component="pictogram"
          />
        )}
        {headline && (
          <Headline
            variant="300"
            className={classes.spacing}
            data-e2e-component="headline"
          >
            {headline}
          </Headline>
        )}
        {children && (
          <Grid className={classes.grid}>
            <Grid.Item LG={6}>
              <Text
                variant="200"
                bold
                data-e2e-component="content"
                className={clsx(classes.spacing, classes.content)}
              >
                {children}
              </Text>
            </Grid.Item>
          </Grid>
        )}
        {onSubmit && (
          <Grid className={classes.grid}>
            <Grid.Item LG={4}>
              <Button
                className={classes.button}
                variant={submitButtonVariant}
                onClick={onSubmit}
                data-e2e-component="submit-button"
                fullWidth
              >
                {submitButtonText || c('notifications.shared.submit')}
              </Button>
            </Grid.Item>
          </Grid>
        )}
        {onCancel && (
          <LinkInline
            StartIcon={ChevronRightIcon}
            className={classes.button}
            onClick={onCancel}
            data-e2e-component="cancel-button"
          >
            {cancelButtonText || c('notifications.shared.cancel')}
          </LinkInline>
        )}
      </div>
    </Card>
  )
}
