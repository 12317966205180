import { GoogleMapsContextProvider } from '@velocity/ui'
import { useEffect, useRef, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { SWRConfig } from 'swr'

import {
  isValidSystemCountry,
  isValidSystemService,
} from '@ngb-frontend/features/flow-config'
import {
  AuthComponent,
  AuthProvider,
  ContentProvider,
  getCountryL10nConfig,
  LocalizationProvider,
  swrCacheProvider,
  useAppConfig,
  useStorage,
} from '@ngb-frontend/shared/context'
import {
  ErrorVariants,
  SystemCode,
  type QueryParams,
} from '@ngb-frontend/shared/types'
import {
  ErrorBoundaryFallback,
  FullScreenLoader,
  NGBVelocityProvider,
} from '@ngb-frontend/shared/ui'
import {
  getUserType,
  generateFetcher,
  setupMSAL,
  getCountryFromFeatureFlag,
} from '@ngb-frontend/shared/utils'

import NoSSR from './NoSSR'
import ErrorPage from '../pages/error/[errorType].page'

import type { PageProps as ConfirmPageProps } from '../pages/confirm/[bookingId].page'
import type { PageProps as IndexPageProps } from '../pages/index.page'
import type { LocalStorageKey } from '@ngb-frontend/shared/context'

type ChildPageProps = Partial<IndexPageProps | ConfirmPageProps>

type PageSessionProps = ChildPageProps & {
  component: React.ComponentType<ChildPageProps>
}

const invalidatingParams: (keyof QueryParams)[] = [
  'ilan',
  'service',
  'licensePlate',
  'caseId',
  'agentId',
  'systemCode',
  'country',
  'bookingId',
  'tenant',
]

type SessionInitialConfig = {
  msalInstance: ReturnType<typeof setupMSAL> | undefined
  swr: Parameters<typeof SWRConfig>[0]['value']
}

const NoSSRLoader = () => {
  return (
    <NoSSR>
      <FullScreenLoader />
    </NoSSR>
  )
}

export const PageSession: React.FC<PageSessionProps> = ({
  component: Component,
  ...childPageProps
}) => {
  const config = useAppConfig()
  const [parsedQuery, setParsedQuery] = useState<
    QueryParams | null | undefined
  >(undefined)

  const { clearLocalStorage, setLocalStorage, getLocalStorage } = useStorage()
  const sessionConfig = useRef<SessionInitialConfig>()

  // Session context setup
  useEffect(() => {
    // Before anything we need to ensure that url params were read and cached.

    const cachedQuery = getLocalStorage('queryParams')

    const nextQuery =
      'query' in childPageProps && childPageProps.query
        ? childPageProps.query
        : cachedQuery

    const resetAllCaches = invalidatingParams.some(
      (p) => nextQuery?.[p] !== cachedQuery?.[p],
    )

    const excludedKeysFromReset: LocalStorageKey[] = []
    if (nextQuery?.bookingId) {
      excludedKeysFromReset.push('bookingHistoryChecked')
    }

    if (resetAllCaches) {
      clearLocalStorage({ exclude: excludedKeysFromReset })
    } else if ('query' in childPageProps && childPageProps.query) {
      excludedKeysFromReset.push('swrCache')
      clearLocalStorage({ exclude: excludedKeysFromReset })
    }

    setLocalStorage('queryParams', nextQuery)

    // Then, initialize our session-specific dependencies
    if (nextQuery) {
      const defaultLocaleConfig = getCountryL10nConfig(nextQuery.country)

      // TODO: Move all MSAL related logic inside the AuthProvider
      const msalInstance =
        !config.disableAuth && defaultLocaleConfig.country
          ? setupMSAL(
              config.azure,
              nextQuery.systemCode,
              defaultLocaleConfig.country,
              defaultLocaleConfig.defaultLocale,
              nextQuery.tenant,
            )
          : undefined

      const swr = {
        fetcher: generateFetcher({
          msalInstance,
          systemCode: nextQuery.systemCode,
        }),
        shouldRetryOnError: false,
        revalidateOnFocus: false,
        provider: swrCacheProvider,
      }

      sessionConfig.current = {
        msalInstance,
        swr,
      }
    }

    setParsedQuery(nextQuery) // Setup complete - proceed with other session-related tasks
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (parsedQuery === undefined) return <FullScreenLoader />

  const ErrorVariant: ErrorVariants | undefined =
    parsedQuery === null
      ? ErrorVariants.Query
      : !Object.values(SystemCode).includes(parsedQuery.systemCode)
      ? ErrorVariants.SystemCode
      : !parsedQuery.country
      ? ErrorVariants.Country
      : !isValidSystemCountry(parsedQuery.systemCode, parsedQuery.country)
      ? ErrorVariants.UnsupportedCountry
      : !isValidSystemService(
          getUserType(parsedQuery.systemCode),
          parsedQuery.service,
        )
      ? ErrorVariants.Service
      : undefined

  const { msalInstance, swr } = sessionConfig.current || {}
  const country = getCountryFromFeatureFlag(parsedQuery?.country, {
    disableNFFleet: config.features.disableNFFleet,
  })

  return (
    <LocalizationProvider country={country}>
      <NGBVelocityProvider>
        <ContentProvider>
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <AuthProvider
              systemCode={parsedQuery?.systemCode}
              disabled={config.disableAuth}
              msalApp={msalInstance?.client}
            >
              <AuthComponent
                systemCode={parsedQuery?.systemCode}
                loadingComponent={NoSSRLoader}
                disabled={config.disableAuth}
                azureRequest={msalInstance?.request}
              >
                <SWRConfig value={swr}>
                  <GoogleMapsContextProvider apiKey={config.google.maps || ' '}>
                    <NoSSR>
                      {!ErrorVariant ? (
                        <Component {...childPageProps} />
                      ) : (
                        <ErrorPage type={ErrorVariant} />
                      )}
                    </NoSSR>
                  </GoogleMapsContextProvider>
                </SWRConfig>
              </AuthComponent>
            </AuthProvider>
          </ErrorBoundary>
        </ContentProvider>
      </NGBVelocityProvider>
    </LocalizationProvider>
  )
}
