import theme from '@velocity/styling/themes/nordea/theme'
import { VelocityProvider } from '@velocity/ui'

import type { VelocityProviderProps } from '@velocity/ui'

export default function Nordea(props: Omit<VelocityProviderProps, 'theme'>) {
  return (
    <VelocityProvider {...props} theme={theme}>
      {props.children}
    </VelocityProvider>
  )
}
